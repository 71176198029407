<template>
  <div class="catalog" v-if="catalogData">
    <HeroBanner
      :isDecorated="isHeroBannerAvailable"
      :title="catalogData.title"
      :data="isHeroBannerAvailable ? catalogData.content.heroBanner : null"
      :parentKey="catalogData.key"
    />

    <div class="container">
      <slot name="crumbs" />
    </div>

    <TextSection
      v-if="catalogData.description"
      :text="catalogData.description"
      variant="catalog-view"
    />

    <SectionComponent
      class="catalog-section categories-carousel-section"
      v-if="catalogData.subValues"
      title="Categories"
      :link="{title: 'Shop all', url: catalogData.productsUrl}"
      titleColor="blue"
    >
      <div class="container">
        <CategoriesCarousel :carouselItems="catalogData.subValues" />
      </div>
    </SectionComponent>

    <SectionComponent class="catalog-section" v-if="catalogData.content && catalogData.content.bannerA">
      <Banner :data="catalogData.content.bannerA" :parentKey="catalogData.key" />
    </SectionComponent>

    <SectionComponent class="catalog-section" v-if="catalogData.content && catalogData.content.bannerB">
      <Banner :data="catalogData.content.bannerB" :parentKey="catalogData.key" />
    </SectionComponent>

    <SectionComponent class="catalog-section" v-if="catalogData.content && catalogData.content.bannerC">
      <Banner :data="catalogData.content.bannerC" :parentKey="catalogData.key" />
    </SectionComponent>
  </div>
  <loader v-else />
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

import PageMixin from '@/mixins/PageMixin'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import ScrollSearchPageByQueryMixin from '@/mixins/ScrollSearchPageByQueryMixin'
import CatalogPageMixin from '@/mixins/CatalogPageMixin'
import HeroBanner from '@/components/HeroBanner'
import TextSection from '@/components/TextSection'
import CategoriesCarousel from '@/components/catalog-sections/CategoriesCarousel'
import Banner from '@/components/Banner'
import SectionComponent from '@/components/SectionComponent'

export default {
  name: 'Catalog',
  components: {
    HeroBanner,
    TextSection,
    CategoriesCarousel,
    Banner,
    SectionComponent
  },
  mixins: [PageMixin, ScreenWidthMixin, ScrollSearchPageByQueryMixin, CatalogPageMixin],
  computed: {
    ...mapState('catalog', ['catalogData']),
    pageTitle () {
      return this.catalogData?.meta?.title
    },
    isHeroBannerAvailable () {
      return !!(this.catalogData.isDecorated && this.catalogData.content.heroBanner)
    },
    metaData () {
      return this.catalogData?.meta
    }
  },
  methods: {
    ...mapActions('catalog', ['setCatalogData', 'unsetCatalogData']),
    ...mapMutations('catalog', { unsetCatalogData: 'UNSET_CATALOG_DATA' })
  },
  async created () {
    this.unsetCatalogData()

    const catalogKeySlug = this.$route.params.pathMatch
    await this.setCatalogData(catalogKeySlug)

    if (!this.catalogData) {
      this.$router.push('/404')
    } else if (!this.catalogData.isLanding) {
      this.$router.push(this.catalogData.productsUrl)
    }

    this.setPageTitle(this.pageTitle)
    this.setMetaTags()
  },
  beforeDestroy () {
    this.unsetMetaTags()
  }
}
</script>
