import { mapMutations } from 'vuex'

export default {
  methods: {
    ...mapMutations('search', {
      setScrollPosition: 'SET_SCROLL_POSITION',
      setPreviousSearchPagePath: 'SET_PREVIOUS_SEARCH_PAGE_PATH'
    })
  },
  beforeRouteLeave (to, from, next) {
    const scrollPosition = to.name === 'ProductDetails' ? window.pageYOffset.toFixed() : 0
    this.setScrollPosition(scrollPosition)
    this.setPreviousSearchPagePath(this.$route.path)
    next()
  }
}
